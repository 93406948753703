<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-top-transition"
        max-width="600"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            block
            class="text-none text-white"
            color="primary"
            title="Nova relacionamento"
            rounded
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              dark
              left
            >
              mdi-file-plus
            </v-icon>
            Cadastrar
          </v-btn>
        </template>
        <template>
          <v-form
            ref="form"
            @submit.prevent="submit"
          >
            <v-card>
              <v-toolbar
                color="primary"
                dark
              >
                Cadastro de relacionamento entre produtor e técnico
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-autocomplete
                      v-model="produtor_fazenda_id"
                      :loading="!produtorFazenda.length"
                      :disabled="!produtorFazenda.length"
                      :items="produtorFazenda"
                      small-chips
                      dense
                      color="blue-grey lighten-2"
                      label="Produtor Fazenda *"
                      item-text="produtor.pessoafisica.nome"
                      item-value="id"
                      clearable
                      :rules="[rules.required, rules.minLength(1, '* Obrigatório')]"
                      validate-on-blur
                      autocomplete="off"
                      outlined
                      rounded
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-subtitle>{{ data.item.fazenda.pessoajuridica.nome_fantasia ?? data.item.fazenda.pessoajuridica.razao_social }}</v-list-item-subtitle>
                          <v-list-item-title>({{ data.item.cod_produtor_fornecedor }}) {{ data.item.produtor.pessoafisica.nome }}</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <template v-slot:selection="data">
                        {{ data.item.produtor.pessoafisica.nome }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  class="text-none text-white mr-3"
                  color="error"
                  rounded
                  @click="dialog = false"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-minus-circle
                  </v-icon>
                  Fechar
                </v-btn>

                <v-btn
                  color="success"
                  class="text-none text-white"
                  rounded
                  @click="submit"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                  Vincular
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
  import TecnicoFormStore, { LOAD_PRODUTOR_FAZENDA_TECNICO_WITHOUT, SUBMIT_PRODUTOR_FAZENDA_TECNICO } from '@/store/modules/forms/tecnico'
  import { mapActions, mapState } from 'vuex'
  import rules from '../../utils/formRules'
  export default {

    props: {
      id: {
        type: String,
        default: null,
      },
    },

    data: () => ({
      rules,
      produtor_fazenda_id: null,

      dialog: false,
    }),

    computed: {
      ...mapState('form/tecnico', ['produtorFazenda']),
    },

    created () {
      if (!this.$store.state.form.tecnico) { this.$store.registerModule(['form', 'tecnico'], TecnicoFormStore) }
      this[LOAD_PRODUTOR_FAZENDA_TECNICO_WITHOUT]()
    },

    methods: {
      ...mapActions('form/tecnico', [LOAD_PRODUTOR_FAZENDA_TECNICO_WITHOUT, SUBMIT_PRODUTOR_FAZENDA_TECNICO]),

      submit () {
        if (!this.$refs.form.validate()) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }

        this.SUBMIT_PRODUTOR_FAZENDA_TECNICO({
          produtor_fazenda_id: this.produtor_fazenda_id,
          tecnico_id: this.id,
        }).then(() => {
          this.dialog = false
        })
      },
    },
  }
</script>
