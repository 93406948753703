<template>
  <div>
    <div class="content">
      <base-snack-bar
        v-model="snackbar.show"
        :message="snackbar.message"
      />
      <crud-cpf-dialog
        :dialog="dialog"
        :titulo="'Técnico'"
        :profile="'tecnico'"
        :store="'tecnicos'"
        :validar="'CPF'"
        @close-dialog="setFields"
      />
      <v-tabs
        v-model="tab"
        center-active
        fixed-tabs
        icons-and-text
      >
        <v-tab>
          Dados Cadastrais
          <v-icon color="grey">
            mdi-flask-outline
          </v-icon>
        </v-tab>
        <v-tab v-if="this.$route.path.includes('alterar')">
          Produtores/Fazendas Vinculadas
          <v-icon color="grey">
            mdi-monitor-dashboard
          </v-icon>
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="tab"
      >
        <v-tab-item>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <base-loading v-if="loading" />
            <v-container
              v-else
              fluid
            >
              <crud-card
                title="Dados Principais"
              >
                <crud-pessoa-fisica-base
                  ref="pf"
                  :exclude="[]"
                  :optional="['rg']"
                  :disabled="disabledFields.pfis"
                  :input-outlined="true"
                />
                <crud-contato
                  :telefones="telefones"
                  :input-outlined="true"
                />
              </crud-card>
              <crud-card title="Vínculos">
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-autocomplete
                      v-model="role"
                      :loading="!roles"
                      :items="roles"
                      small-chips
                      dense
                      plearable
                      rounded
                      outlined
                      color="blue-grey lighten-2"
                      label="Papel"
                      item-text="description"
                      item-value="id"
                      :rules="[rules.required]"
                      autocomplete="nope"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-autocomplete
                      v-model="unidades"
                      :loading="!unidadesNegocio"
                      :items="unidadesNegocio"
                      small-chips
                      dense
                      color="blue-grey lighten-2"
                      label="Unidades de negócio*"
                      item-text="filial"
                      item-value="id"
                      multiple
                      clearable
                      :rules="[rules.minLength(1, '* Obrigatório')]"
                      validate-on-blur
                      autocomplete="off"
                      outlined
                      rounded
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-if="data.index < 6"
                          rounded
                          small
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="remove(data.item)"
                        >
                          {{ data.item.filial }}
                        </v-chip>
                        <span
                          v-if="data.index === 6"
                          class="grey--text caption"
                        >
                          (+{{ unidades.length - 6 }} outros)
                        </span>
                      </template>
                      <template v-slot:item="data">
                        <template v-if="testType(data.item)">
                          <v-list-item-content v-text="data.item" />
                        </template>
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title>{{ data.item.filial }}</v-list-item-title>
                            <v-list-item-subtitle>{{ ( data.item.cnpj + '  -  ' + data.item.razao_social ) }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-autocomplete
                      v-model="supervisor_id"
                      :loading="!supervisores"
                      :items="supervisores"
                      small-chips
                      dense
                      color="blue-grey lighten-2"
                      label="Supervisor"
                      item-text="nome"
                      item-value="id"
                      clearable
                      :rules="[rules.minLength(1, '* Obrigatório')]"
                      validate-on-blur
                      autocomplete="off"
                      outlined
                      rounded
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>{{ data.item.nome }}</v-list-item-title>
                          <v-list-item-subtitle>{{ data.item.cpf }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </crud-card>
              <v-row
                class="pa-3"
                justify="center"
                justify-sm="end"
              >
                <v-btn
                  class="text-none text-white mr-3"
                  color="error"
                  rounded
                  @click="$router.push({ path: `/${$user.get().role}/tecnicos` })"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-minus-circle
                  </v-icon>
                  Cancelar
                </v-btn>
                <v-btn
                  color="success"
                  class="text-none text-white"
                  :disabled="desabilitaBotao"
                  rounded
                  @click="submitForm"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                  {{ isEditing ? 'Salvar' : 'Cadastrar' }}
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-tab-item>
        <v-tab-item>
          <v-container
            v-if="$route.query.id"
            fluid
          >
            <base-loading v-if="loading" />
            <crud-card
              v-else
              title="Produtores / Fazendas"
            >
              <crud-list
                v-model="produtorFazendaTecnico"
                :headers="headers"
                :actions="actions"
                :loading="loading"
                class="mb-10"
                :store="'produtorFazendaTecnico'"
                :item-height="$vuetify.breakpoint.xl ? '390' : '300'"
              >
                <template v-slot:btnCadastro>
                  <v-col>
                    <card-tecnico-produtor-fazenda :id="$route.query.id" />
                  </v-col>
                </template>
              </crud-list>
            </crud-card>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<script>
  import TecnicoFormStore, { BOOTSTRAP_PROFILE, SUBMIT, LOAD_PRODUTOR_FAZENDA_TECNICO, DELETE_TECNICO_PRODUTOR_FAZENDA } from '@/store/modules/forms/tecnico'
  import { mapActions, mapState } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  import CardTecnicoProdutorFazenda from './CardTecnicoProdutorFazenda.vue'

  export default {
    components: { CardTecnicoProdutorFazenda },

    data () {
      return {
        tab: 0,
        valid: false,
        loadedData: false,
        telefones: [
          {
            tipo: 'Comercial',
            format: 'com',
            title: 'Telefone 1',
            required: true,
          },
          { tipo: 'Comercial', format: 'com', title: 'Telefone 2' },
        ],
        rules: rules,
        snackbar: { message: '', show: false },
        actions: [
          {
            title: 'Excluir Relação',
            color: 'red darken-3',
            click: produtorFazenda => this.DELETE_TECNICO_PRODUTOR_FAZENDA({ produtorFazenda }),
            icon: 'mdi-trash-can-outline',
            permission: 'excluir_tecnico',
          },
        ],

        headers: [
          { text: '', sortable: false, groupable: false, floatingfilter: false, value: 'data-table-expand' },
          { text: '', sortable: false, groupable: false, floatingfilter: false, value: 'actions', width: '40px' },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'Fazenda', value: 'fazenda.pessoajuridica.nome_fantasia', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'Produtor', value: 'produtor.pessoafisica.nome', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'Técnico', value: 'tecnico.pessoafisica.nome', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'Código Fornecedor', value: 'cod_produtor_fornecedor', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'Código Cliente', value: 'cod_produtor_cliente', width: 'auto' },
        ],
      }
    },
    computed: {
      ...mapState('form/tecnico', ['unidadesNegocio', 'roles', 'loading', 'isEditing', 'supervisores', 'produtorFazendaTecnico']),
      ...mapFields('form', ['data.role', 'data.unidades', 'data.supervisor_id']),
      ...mapFields('form/tecnico', ['dialog']),
      disabledFields () {
        if (this.loaded || this.isEditing) {
          return { pfis: ['cpf'], pjur: ['cnpj'] }
        }
        return { pfis: ['cpf'], pjur: ['cnpj'] }
      },
      desabilitaBotao () {
        return this.loading || !this.valid
      },
    },
    created () {
      if (!this.$store.state.form.tecnico) { this.$store.registerModule(['form', 'tecnico'], TecnicoFormStore) }
      const id = this.$route.query.id
      this.BOOTSTRAP_PROFILE({ id })
      this.LOAD_PRODUTOR_FAZENDA_TECNICO({ id })
    },

    methods: {
      ...mapActions('form/tecnico', [BOOTSTRAP_PROFILE, SUBMIT, LOAD_PRODUTOR_FAZENDA_TECNICO, DELETE_TECNICO_PRODUTOR_FAZENDA]),
      async setFields (data) {
        this.dialog = false

        if (data.value) {
          const { tipo, value } = data
          this.picked = tipo === 'cpf' ? 'fisica' : 'juridica'
          this.$store.commit('form/UPDATE_FORM_FIELD', { [tipo]: value })
          return
        }

        this.loadedData = true

        const id = data.id
        this.$router.push({
          path: `/${this.$user.get().role}/tecnicos/cadastro`,
          query: { id },
        })
        this.BOOTSTRAP_PROFILE({ id })
      },
      testType (item) {
        return typeof item !== 'object'
      },
      remove (item) {
        const index = this.unidades.indexOf(item.id)
        if (index >= 0) this.unidades.splice(index, 1)
      },
      submitForm () {
        this.$refs.pf.$v.$touch()
        if (!this.$refs.form.validate() || this.$refs.pf.$v.$invalid) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }
        this.SUBMIT({ router: this.$router, user: this.$user })
      },
    },
  }
</script>
