import { getField, updateField } from 'vuex-map-fields'
import api from '@/api/api'
import { Tecnico } from '@/models/Tecnico'
// import router from '../../../Routes/router'
import Swal from 'sweetalert2'
import { tecnicoTransformer } from '@/utils/transformers'
// import { v4 } from 'uuid'

export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const LOAD_UNIDADES = 'LOAD_UNIDADES'
export const LOAD_SUPERVISORES = 'LOAD_SUPERVISORES'
export const LOAD_ROLES = 'LOAD_ROLES'
export const SUBMIT = 'SUBMIT'
export const RESET_STATE = 'RESET_STATE'
export const LOAD_PRODUTOR_FAZENDA_TECNICO = 'LOAD_PRODUTOR_FAZENDA_TECNICO'
export const LOAD_PRODUTOR_FAZENDA_TECNICO_WITHOUT = 'LOAD_PRODUTOR_FAZENDA_TECNICO_WITHOUT'
export const SUBMIT_PRODUTOR_FAZENDA_TECNICO = 'SUBMIT_PRODUTOR_FAZENDA_TECNICO'
export const DELETE_TECNICO_PRODUTOR_FAZENDA = 'DELETE_TECNICO_PRODUTOR_FAZENDA'

const getDefaultState = () => {
  return {
    unidadesNegocio: [],
    supervisores: [],
    roles: [],
    step: 1,
    isEditing: false,
    loading: true,
    dialog: false,
    produtorFazendaTecnico: [],
    produtorFazenda: [],
  }
}

const state = getDefaultState()

const getters = {
  getField,
  supervisores: state => state.supervisores,
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
}

const actions = {
  async [BOOTSTRAP_PROFILE] ({ dispatch, commit, state }, { id }) {
    commit(RESET_STATE)
    commit(SET_STATE, { loading: true, isEditing: false })
    dispatch('form/BOOTSTRAP_FORM', { model: new Tecnico() }, { root: true })
    await dispatch(LOAD_UNIDADES)
    await dispatch(LOAD_SUPERVISORES)
    await dispatch(LOAD_ROLES)
    if (id) {
      commit(SET_STATE, { isEditing: true })
      await api.getEntidade('tecnico', id).then(response => {
        commit(SET_STATE, { loading: false })
        dispatch('form/BOOTSTRAP_FORM', { model: new Tecnico(tecnicoTransformer(response.data[0])) }, { root: true })
      })
    } else {
      commit(SET_STATE, { dialog: true, loading: false })
    }
  },
  async [LOAD_UNIDADES] ({ dispatch, commit }) {
    await api.listEntidade('unidadeNegocio').then(
      unidadesNegocio => {
        commit(SET_STATE, { unidadesNegocio })
      },
    )
  },
  async [LOAD_SUPERVISORES] ({ dispatch, commit }) {
    await api.listEntidade('supervisor').then(
      supervisores => {
        commit(SET_STATE, { supervisores })
      },
    )
  },
  async [LOAD_ROLES] ({ dispatch, commit }) {
    await api.getEntidade('role', 3).then(
      response => {
        const roles = response.data
        commit(SET_STATE, { roles })
      },
    )
  },
  async [SUBMIT] ({ dispatch, commit, state, rootState }, { router, user }) {
    const form = { ...rootState.form.data, unidade: rootState.form.data.unidades }
    const [day, month, year] = form.data_nascimento.split('/')
    form.data_nascimento = year + '-' + month + '-' + day
    form.role_id = form.role
    Swal.fire({
      title: 'Enviando dados!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        (() => {
          return state.isEditing
            ? api.salvarEntidade(form, 'tecnico', form.id)
            : api.cadastrarEntidade(form, 'tecnico')
        })().then(
          data => {
            window.Toast.fire(state.isEditing
              ? 'Técnico atualizado com sucesso!'
              : 'Técnico cadastrado com sucesso!', '', 'success')
            dispatch('tecnicos/BOOTSTRAP', null, { root: true })
            router.push({
              path: `/${user.get().role}/tecnicos`,
            })
          },
          error => {
            window.Toast.fire(error.errors ? error.errors : error.message, '', 'error')
          },
        )
      },
    })
  },

  async [LOAD_PRODUTOR_FAZENDA_TECNICO] ({ commit }, { id }) {
    api.getEntidade('tecnico/produtoresFazendas/list', id).then(response => {
      commit(SET_STATE, { produtorFazendaTecnico: response.data })
    })
  },

  async [LOAD_PRODUTOR_FAZENDA_TECNICO_WITHOUT] ({ commit }) {
    api.listEntidade('tecnico/produtoresFazendas/list').then(response => {
      commit(SET_STATE, { produtorFazenda: response })
    })
  },

  async [SUBMIT_PRODUTOR_FAZENDA_TECNICO] ({ dispatch, commit, state, rootState }, { produtor_fazenda_id, tecnico_id }) {
    const form = {
      produtor_fazenda_id: produtor_fazenda_id,
      tecnico_id: tecnico_id,
    }

    await Swal.fire({
      title: 'Vinculando produtor/fazenda ao técnico!',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Vincular',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,

      preConfirm: () => {
        return api.cadastrarEntidade(form, 'tecnico/produtoresFazendas').then(
          response => {
            dispatch(LOAD_PRODUTOR_FAZENDA_TECNICO, { id: tecnico_id })
            commit(SET_STATE, { produtorFazenda: [] })
            dispatch(LOAD_PRODUTOR_FAZENDA_TECNICO_WITHOUT)
          },
          error => {
            window.Toast.fire(error.errors ? error.errors : error.message, '', 'error')
          },
        )
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.Toast.fire('A relação entre o Produtor/Fazenda e tecnico foi criada', '', 'success')
      }
    })
  },

  async [DELETE_TECNICO_PRODUTOR_FAZENDA] ({ dispatch, commit }, { produtorFazenda }) {
    await Swal.fire({
      title: 'Excluir relação!',
      text: 'Tem certeza que deseja excluir a relação entre o Produtor/Fazenda e o técnico?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,

      preConfirm: () => {
        return api.inativarEntidade('tecnico/produtoresFazendas', produtorFazenda.id).then(
          response => {
            commit(SET_STATE, { produtorFazendaTecnico: state.produtorFazendaTecnico.filter(item => item.id !== produtorFazenda.id) })
            dispatch(LOAD_PRODUTOR_FAZENDA_TECNICO, { id: produtorFazenda.tecnico_id })
            commit(SET_STATE, { dialogVinculo: false })
          },
          error => {
            window.Toast.fire(error.errors ? error.errors : error.message, '', 'error')
          },
        ).catch(error => {
          Swal.fire(error.errors ? error.errors : error.message, '', 'error')
        })
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('A relação entre o Produtor/Fazenda e tecnico foi removida', '', 'success')
      }
    }).catch(error => {
      Swal.fire(error.errors ? error.errors : error.message, '', 'error')
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
