export class Tecnico {
  constructor ({
    id,
    cpf,
    rg,
    unidades,
    supervisor_id,
    nome,
    email,
    data_nascimento,
    sexo,
    telefones,
    role,
  } = {
      id: '',
      cpf: '',
      rg: '',
      unidades: [],
      supervisor_id: '',
      nome: '',
      email: '',
      data_nascimento: '',
      sexo: '',
      telefones: [],
      role: '',
    }) {
    this.id = id
    this.cpf = cpf
    this.rg = rg
    this.unidades = unidades
    this.supervisor_id = supervisor_id
    this.nome = nome
    this.email = email
    this.data_nascimento = data_nascimento
    this.sexo = sexo
    this.role = role
    this.telefones = telefones
  }
}

export function createTecnico (data) {
  return Object.freeze(new Tecnico(data))
}
